import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { FlowRunPathAttributes } from "./types";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: "select_account" });
googleAuthProvider.addScope("email");

export const msftAuthProvider = new OAuthProvider("microsoft.com");
msftAuthProvider.setCustomParameters({ prompt: "select_account" });
msftAuthProvider.addScope("email.read");

export const database = getDatabase(app);

export const firebaseStorage = getStorage(app);

export const FLOW_RUNS_BASE_PATH = "flow_runs/workspaces";

export const getPathForFlowRun = (inputs: FlowRunPathAttributes) => {
  const { workspaceId, repoOwner, repoName, prNumber, commitHash } = inputs;

  return `${FLOW_RUNS_BASE_PATH}/${workspaceId}/${repoOwner}/${repoName}/${prNumber}/runs/${commitHash}`;
};

export const getPathForFlowRunStatus = (inputs: FlowRunPathAttributes): string => {
  return `${getPathForFlowRun(inputs)}/status`;
};
