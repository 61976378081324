import { AuthProvider, getAdditionalUserInfo, signInWithPopup, signOut } from "firebase/auth";
import { auth } from "config/firebase";
import backend from "client/backend";
import { AxiosInstance } from "axios";

export const signIn = async (axiosClient: AxiosInstance, provider: AuthProvider) => {
  return signInWithPopup(auth, provider).then(async (userCredential) => {
    const isNewUser = userCredential && getAdditionalUserInfo(userCredential)?.isNewUser;

    if (isNewUser) {
      const resp = await backend.user.register(
        {
          email: userCredential.user.email!,
          uid: userCredential.user.uid,
          firstName: userCredential.user.displayName?.split(" ")[0] || "",
          lastName: userCredential.user.displayName?.split(" ")[1] || "",
        },
        axiosClient,
      );

      if (resp.status === 403) {
        signOut(auth);
        throw new Error("User registration failed");
      }
    }

    return Promise.resolve("success");
  });
};

export const signOutUser = () =>
  signOut(auth)
    .then(() => {})
    .catch((error) => {
      console.log("Cannot sign out: ", error.message);
    });
