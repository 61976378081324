import { AuthContext } from "../../../../context/AuthContext";
import { useContext } from "react";

import { useClasses } from "./loginPage.styles";
import { QuillButton } from "components/ui/QuillButton";
import { googleIcon, microsoftIcon } from "features/auth/icons";
import { signIn } from "features/auth/util";
import { NotificationContext } from "context/NotificationContext";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAxios } from "context/AxiosContext";
import { googleAuthProvider, msftAuthProvider } from "config/firebase";
import { AuthProvider } from "firebase/auth";

export function LoginPage() {
  const classes = useClasses();
  const { checkingAuthState, isQuillLoggedIn } = useContext(AuthContext);
  const { notifyUser } = useContext(NotificationContext);
  const [params] = useSearchParams();
  const from = params.get("from") || "/";
  const axiosClient = useAxios();

  const handleSignIn = async (provider: AuthProvider) => {
    try {
      await signIn(axiosClient, provider);
    } catch (error: unknown) {
      notifyUser({
        title: "You've not yet been invited to try out Quill.",
        type: "error",
        description: "Visit getquill.dev to join the waitlist",
      });
    }
  };

  if (isQuillLoggedIn && !checkingAuthState) {
    return <Navigate replace to={from} />;
  }

  const signInButtons = () => (
    <div className={classes.container}>
      <QuillButton
        disabled={checkingAuthState}
        className={classes.loginButton}
        icon={googleIcon}
        iconPosition="before"
        onClick={() => handleSignIn(googleAuthProvider)}
      >
        {checkingAuthState ? "Signing in..." : "Sign in with Google"}
      </QuillButton>
      <QuillButton
        disabled={checkingAuthState}
        className={classes.loginButton}
        icon={microsoftIcon}
        iconPosition="before"
        onClick={() => handleSignIn(msftAuthProvider)}
      >
        {checkingAuthState ? "Signing in..." : "Sign in with Microsoft"}
      </QuillButton>
    </div>
  );

  return <div className={classes.login}>{signInButtons()}</div>;
}
