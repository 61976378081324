import { useRouteError } from "react-router-dom";

import error404 from "./error404.png";
import errorAny from "./errorAny.png";
import { useClasses } from "./errorPage.styles";

type ErrorPageProps = {
  title?: string;
  description?: string;
};

export function ErrorPage({
  title = "Oops!",
  description = "Sorry, an unexpected error has occurred.",
}: ErrorPageProps) {
  // const { title, description } = props;
  const error: any = useRouteError();
  const classes = useClasses();
  const errorStatus = error && error.status;

  const getErrorText = (): string => {
    if (errorStatus === 401) {
      return "You are not authorized to view this page.";
    }
    if (errorStatus === 404) {
      return "The page you are looking for does not exist.";
    }
    return "";
  };

  return (
    <div id="error-page" className={classes.error}>
      {errorStatus === 404 && <img className={classes.errorImage} src={error404} alt="Mobile Device" />}
      {errorStatus !== 404 && <img className={classes.errorImage} src={errorAny} alt="Mobile Device" />}
      <h1>{title}</h1>
      <p>{description}</p>
      <div>{getErrorText()}</div>
    </div>
  );
}
