import { makeStyles } from "@fluentui/react-components";

export const useClasses = makeStyles({
  error: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    flexDirection: "column",
    textAlign: "center",
  },
  errorImage: {
    maxHeight: "400px",
    width: "auto",
    display: "block",
  },
});
